<template>
    <div class="component-order-list">
        <div class="order-list" v-if="orderList?.length">
            <h2>{{ $t('orderList.title') }}</h2>

            <div class="order-list-wrapper row">
                <div
                    class="order-card-wrapper col col-12 col-lg-6"
                    v-for="order in orderList" :key="order.id"
                >
                    <div class="order-card">
                        <h3 class="position-title">
                            {{ order.position.title }}
                        </h3>

                        <div>{{ order.model_type.title }}</div>

                        <div class="order-date">
                            {{ $moment(order?.created_at).format('LLL') }}
                        </div>

                        <div class="order-status-wrapper">
                            <div>
                                <font-awesome-icon
                                    class="icon-invoice-download"
                                    :class="{ 'disable-element': downloadInvoiceId === order.id }"
                                    icon="download"
                                    @click="showInvoice(order.id)"
                                />

                                <span
                                    class="order-status"
                                    :class="'order-status--' + order.status.id"
                                >
                                    {{ order.status.title }}
                                </span>
                            </div>

                            <div class="show-position" @click="showPosition(order.position.slug)">
                                {{ $t('orderList.showPosition') }}
                            </div>
                        </div>

                        <!-- <div>cena: {{ order.price }}</div>-->
                        <!-- <div>Zaplatene: {{ $moment(order?.confirmed_at).format('LLL') }}</div>-->
                        <!-- <div>Expiruje: {{ $moment(order?.expired_at).format('LLL') }}</div>-->
                    </div>
                </div>
            </div>

            <div class="pagination-wrapper text-center">
                <vue-awesome-paginate
                    :class="{ 'disable-element': pagination.loading }"
                    v-model="pagination.currentPage"
                    :items-per-page="pagination.perPage"
                    :total-items="pagination.totalItems"
                    :max-pages-shown="3"
                    :on-click="getOrderList"
                />
            </div>
        </div>
    </div>
</template>

<script>
import userService from "../../functions/services/userService";

export default {
    name: "OrderList",
    mixins: [ userService ],
    data() {
        return {
            orderList: [],
            downloadInvoiceId: null,
            pagination: {
                currentPage: 1,
                perPage: 10,
                totalItems: 0,
                loading: false
            },
        };
    },
    mounted() {
        this.getOrderList()
    },
    methods: {
        getOrderList() {
            this.pagination.loading = true

            let bodyParams = [
                { key: 'page', value: this.pagination.currentPage },
                { key: 'per_page', value: this.pagination.perPage },
            ]

            this.axiosGetOrderList(bodyParams).then(res => {
                // console.log('axiosGetOrderList', res);
                const data = res.data

                this.pagination.totalItems = data.meta.total
                this.orderList = data.data
            }).finally(() => {
                this.pagination.loading = false
            })
        },
        showPosition(positionSlug) {
            this.$router.push({ name: 'PagePositionDetail', params: { slug: positionSlug } })
        },
        showInvoice(orderId) {
            this.downloadInvoiceId = orderId
            this.$axios.get(this.$config.API_URL_V1 + 'order/invoice/' + orderId, this.$config.HEADERS).then(res => {
                // console.log('showInvoice', res);
                const invoicePdfUrl = res.data.data.pdf || ''
                if (invoicePdfUrl?.length) {
                    window.open(invoicePdfUrl, '_blank')
                } else {
                    this.$root.$refs.ref_toast.showToast(this.$t('orderList.noInvoice'), 'toast-danger')
                }
            }, () => {
                this.$root.$refs.ref_toast.showToast(this.$t('orderList.noInvoice'), 'toast-danger')
            }).finally(() => {
                this.downloadInvoiceId = null
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.component-order-list::v-deep(.order-list) {
    margin: 50px 0;

    .order-list-wrapper {
        margin-top: 10px;
        margin-bottom: 10px;

        .order-card-wrapper {
            margin-top: 10px;
            margin-bottom: 10px;

            .order-card {
                padding: 10px 20px;
                background: $bg--gray;
                border-radius: 6px;
                height: 100%;

                .position-title {
                    overflow-wrap: anywhere;
                    white-space: normal;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                }

                .order-date {
                    color: $text--gray;
                    font-size: 14px;
                }

                .order-status-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .order-status {
                        padding: 2px 10px;
                        color: $white;
                        border-radius: 6px;

                        &--1 {
                            background: $warning;
                        }

                        &--2 {
                            background: $success;
                        }

                        &--3 {
                            background: $danger;
                        }
                    }

                    .icon-invoice-download {
                        //margin-left: 10px;
                        margin-right: 10px;
                        cursor: pointer;
                    }

                    .show-position {
                        cursor: pointer;
                        font-size: 14px;
                        text-align: right;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
</style>