<template>
    <div class="component-editor-commission">
        <div class="editor-commission">
            <h2>{{ $t('editorCommission.title') }}</h2>

            <div class="commission-values-wrapper row">
                <div class="col col-12 col-sm-6 col-lg-3">
                    <div class="commission-info">
                        <div class="commission-info__title">{{ $t('editorCommission.currentCredit') }}</div>
                        <div class="commission-info__value">{{ creditInfo.credit }} €</div>
                    </div>
                </div>
                <div class="col col-12 col-sm-6 col-lg-3">
                    <div class="commission-info">
                        <div class="commission-info__title">{{ $t('editorCommission.creditPendingPayment') }}</div>
                        <div class="commission-info__value">{{ creditInfo.credit_unpaid }} €</div>
                    </div>
                </div>
                <div class="col col-12 col-sm-6 col-lg-3">
                    <div class="commission-info">
                        <div class="commission-info__title">{{ $t('editorCommission.creditCanBePayment') }}</div>
                        <div class="commission-info__note">({{ $t('editorCommission.paymentInfo', { value: getWebSettingsByKey('min_credit_request') }) }})</div>
                        <div class="commission-info__value">{{ creditInfo.max_amount_to_pay }} €</div>
                    </div>
                </div>
                <div class="col col-12 col-sm-6 col-lg-3">
                    <div class="commission-info  commission-info--totally-earned">
                        <div class="commission-info__title">{{ $t('editorCommission.totallyEarned') }}</div>
                        <div class="commission-info__value">{{ creditInfo.total_earned }} €</div>
                    </div>
                </div>
            </div>

            <MainButton
                :is-disabled="!creditInfo.allow_credit_request || !enteredIban"
                btn-type="submit"
                :btn-text="$t('editorCommission.requestPayment')"
                :is-loading="loaders.commissionRequest"
                @handle-click="payCommissionRequest"
            />

            <div class="missing-iban-warning" v-if="!enteredIban">
                {{ $t('editorCommission.missingIbanWarning') }}
            </div>
        </div>
    </div>
</template>

<script>
import MainButton from "../elements/MainButton.vue";
import { mapGetters } from "vuex";

export default {
    name: "EditorCommission",
    components: {
        MainButton,
    },
    props: {
        creditInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        enteredIban: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loaders: {
                commissionRequest: false
            }
        };
    },
    methods: {
        payCommissionRequest() {
            let postData = {}
            // postData['amount'] = 0

            this.loaders.commissionRequest = true
            this.$axios.post(this.$config.API_URL_V1 + 'payment-request', postData, this.$config.HEADERS)
                .then(res => {
                    // console.log('payCommissionRequest', res);
                    this.$emit('update:credit-info', res.data.data?.user)
                    this.$root.$refs.ref_toast.showToast(res.data.message, 'toast-success')
                }, e => {
                    const message = e?.response?.data?.message
                    this.$root.$refs.ref_toast.showToast(message, 'toast-danger')
                }).finally(() => {
                    this.loaders.commissionRequest = false
                })
        }
    },
    computed: {
        ...mapGetters({
            getWebSettingsByKey: 'web/getWebSettingsByKey',
        })
    }
};
</script>

<style lang="scss" scoped>
.component-editor-commission::v-deep(.editor-commission) {
    margin: 50px 0;

    .commission-values-wrapper {
        margin-top: 10px;
        margin-bottom: 20px;

        .col {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .commission-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 5px;

            height: 100%;

            color: white;
            border-radius: 6px;
            background: $primary;

            &--totally-earned {
                background: $tertiary;
            }

            &__title,
            &__value,
            &__note {
                text-align: center;
            }

            &__title {
                //
            }

            &__value {
                font-weight: 600;
                font-size: 18px;
            }

            &__note {
                opacity: 0.7;
                font-size: 12px;
                font-style: italic;
            }
        }
    }

    .missing-iban-warning {
        margin-top: 5px;

        color: $danger;
        text-align: center;
        font-style: italic;
    }
}
</style>