<template>
    <div class="component-transaction-history-list">
        <div class="transaction-history-list" v-if="transactionHistoryList?.length">
            <h2>{{ $t('transactions.title') }}</h2>

            <div class="transaction-list">
                <div
                    class="transaction-card"
                    :class="additionalClass(transaction.type)"
                    v-for="transaction in transactionHistoryList" :key="transaction.id"
                >
                    <div class="transaction-card__info">
                        <div class="transaction-title">{{ transaction.type?.title }}</div>
                        <div class="transaction-date">{{ $moment(transaction.created_at).format('LLL') }}</div>
                    </div>

                    <div class="transaction-card__profit">
                        {{ formatProfit(transaction.type?.id, transaction.profit) }}
                    </div>
                </div>
            </div>

            <div class="pagination-wrapper text-center">
                <vue-awesome-paginate
                    :class="{ 'disable-element': pagination.loading }"
                    v-model="pagination.currentPage"
                    :items-per-page="pagination.perPage"
                    :total-items="pagination.totalItems"
                    :max-pages-shown="3"
                    :on-click="getTransactionHistory"
                />
            </div>
        </div>
    </div>
</template>

<script>
import userService from "../../functions/services/userService";

export default {
    name: "TransactionHistoryList",
    mixins: [ userService ],
    data() {
        return {
            transactionHistoryList: [],

            pagination: {
                currentPage: 1,
                perPage: 15,
                totalItems: 0,
                loading: false
            },
        };
    },
    mounted() {
        this.getTransactionHistory()
    },
    methods: {
        getTransactionHistory() {
            this.pagination.loading = true

            let bodyParams = [
                { key: 'page', value: this.pagination.currentPage },
                { key: 'per_page', value: this.pagination.perPage },
            ]

            this.axiosGetTransactionList(bodyParams).then(res => {
                // console.log('axiosGetTransactionList', res);
                const data = res.data

                this.pagination.totalItems = data.meta.total
                this.transactionHistoryList = data.data
            }).finally(() => {
                this.pagination.loading = false
            })
        },
    },
    computed: {
        additionalClass() {
            return transactionType => 'transaction-card--' + transactionType.id
        },
        formatProfit() {
            return (typeId, profit) => {
                const typeValue = typeId === '1' || typeId === '2' ? '+' : '-'
                return `${typeValue} ${Math.abs(profit)} €`
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.component-transaction-history-list::v-deep(.transaction-history-list) {
    //background: $bg--gray;

    .transaction-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin: 20px 0;

        .transaction-card {
            flex-basis: calc(100%/3 - 20px/3);
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding: 5px 15px;

            background: $white;
            border-radius: 6px;

            &__info {
                .transaction-title {
                    font-weight: 600;
                }

                .transaction-date {
                    color: $text--gray;
                    font-size: 13px;
                }
            }

            &__profit {
                font-weight: 600;
            }

            &--1,
            &--2 {
                border: 1px solid $primary;
            }

            &--3 {
                color: $white;
                background: $success;
                border: 1px solid $success;

                .transaction-date {
                    color: $white;
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .transaction-list {
            .transaction-card {
                flex-basis: calc(100% / 2 - 20px / 2);
            }
        }
    }

    @media (max-width: 768px) {
        .transaction-list {
            .transaction-card {
                flex-basis: 100%;
            }
        }
    }
}
</style>